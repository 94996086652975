
















import { Component, Prop, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import Form from "@/shared/components/form/Form.vue";
import FormBase from "@/shared/classes/form/form-base";
import { GlobalActions } from "@/shared/store/global/global.actions";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import { HttpMethod } from "@/shared/helpers/requests.helper";
import { getAdditionalLanguagesByVersion, getNodeApiUrlByVersion } from "@/config";
import { FieldTypes } from "@/shared/components/form/field-types";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import FormGroup from "@/shared/classes/form/group";
import ArrayField from "@/shared/classes/form/fields/array-field";
import _ from "lodash";
import CoursesTest from "@/shared/modules/courses-tests/courses-test.model";
import { validateFields } from "@/shared/helpers/validate";

@Component({
  components: { Form, PageHeader },
  methods: { __ },
})
export default class CreateCoursesTestDialog extends Vue {
  static GROUP_QUESTION = 'question'
  static GROUP_ANSWERS = 'answers'

  @Prop() meta!: any
  form: FormBase | null = null

  created() {
    this.form = new FormBase()
      .setEndpoint(`${getNodeApiUrlByVersion()}/course-tests`)
      .setMethod(HttpMethod.POST)
      .setTranslatable(true)
      .setGroups([
        new FormGroup()
          .setKey(CreateCoursesTestDialog.GROUP_QUESTION)
          .setTitle(__('admin.components.courses.tests.form.question')),
        new FormGroup()
          .setKey(CreateCoursesTestDialog.GROUP_ANSWERS)
          .setTitle(__('admin.components.courses.tests.form.answers'))
      ])
      .setModel(CoursesTest)
      .setFields([
        new Field()
          .setKey('text')
          .setType(FieldTypes.editorField)
          .setSize(FieldSizes.full)
          .setGroup(CreateCoursesTestDialog.GROUP_QUESTION)
          .isRequired()
          .setTranslatable(true),
        new ArrayField()
          .setKey('answers')
          .setSize(FieldSizes.full)
          .setGroup(CreateCoursesTestDialog.GROUP_ANSWERS)
          .setInitialLength(3)
          .setNonDeletableAmount(3)
          .setDeleteButtonProps({ color: "transparent", elevation: 0, class: "" })
          .setAddButtonProps({ isIcon: true, class: "justify-end mr-3" })
          .setDense(true)
          .setChildren([
            new Field()
              .setKey('isCorrect')
              .setType(FieldTypes.checkbox)
              .setSize(FieldSizes.oneTwelfth)
              .setOnChange((value: boolean, key?: string) => {
                this.form?.data.answers.forEach((answer: any) => answer.isCorrect = false)
                if (key) _.set(this.form?.data, key, value);

                if (value) {
                  const errors: any = { ... this.form?.errors };
                  this.form?.data.answers.forEach((answer: any, index: number) => {
                    delete errors[`answers.${index}.isCorrect`]
                  })
                  this.form?.setErrors(errors);
                }
              }),
            new Field()
              .setKey('text')
              .setSize(FieldSizes.elevenTwelfth)
              .setTitle(`${__('admin.components.courses.tests.form.choice')} {index}`)
              .setDense(true)
              .isRequired()
              .setTranslatable(true)
          ]),
      ])
      .setValidate((data: any) => {
        let errors: any = validateFields(data, this.form?.fields, {
          languages: getAdditionalLanguagesByVersion(),
          onlyRegionLangRequired: true
        })
        const hasCorrectAnswer = data.answers.some((answer: any) => answer.isCorrect)
        if (!hasCorrectAnswer) {
          if (!errors) errors = {};
          data.answers.forEach((answer: any, index: number) => {
            errors[`answers.${index}.isCorrect`] = { has: true, count: 1, messages: [''] }
          })
        }

        if (Object.keys(errors).length) {
          this.form?.setErrors(errors)
          return false
        }

        return true
      })
      .setShowDefaultValidationError(true)
      .setInjectValues({
        courseUuid: this.meta.courseUuid,
        type: CoursesTest.ONE_ANSWER_TYPE,
      })
      .setOnSuccess(() => {
        this.$store.dispatch(GlobalActions.closeDialog)
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __('admin.components.courses.tests.create-dialog.on-success'),
        })
        if (typeof this.meta.onSuccess === 'function') this.meta.onSuccess();
      })
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }
}
