import Model from '@/shared/classes/model'
import ICoursesTestResponse from "@/shared/modules/courses-tests/courses-test-response.interface";

export default class CoursesTest extends Model<ICoursesTestResponse> {
  static ONE_ANSWER_TYPE = 'one-answer'

  courseUuid!: string
  type!: string
  text!: string
  answers!: {
    isCorrect: boolean;
    text: string;
  }


  transform(data: ICoursesTestResponse): void {
    super.transform(data)

    this.courseUuid = data.courseUuid
    this.type = data.type
    this.text = data.text
    this.answers = data.answers
  }
}
