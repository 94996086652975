
















































import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import { getNodeApiUrlByVersion } from "@/config";
import { GlobalActions } from "@/shared/store/global/global.actions";
import Course from "@/shared/modules/courses/course.model";
import baseHttp from "@/shared/http";
import CoursesTestTitleDialog from "@/admin/components/courses/CoursesTestTitleDialog.vue";
import localeHelper from "@/shared/helpers/locale.helper";
import CoursesTestDescriptionDialog from "@/admin/components/courses/CoursesTestDescriptionDialog.vue";
import CoursesTestCompletionNoticeDialog from "@/admin/components/courses/CoursesTestCompletionNoticeDialog.vue";
import CoursesTestPassingThresholdDialog from "@/admin/components/courses/CoursesTestPassingThresholdDialog.vue";
import DataTableBase from "@/shared/classes/components/data-table/data-table";
import DataTableHeader from "@/shared/classes/components/data-table/data-table-header";
import EditAction from "@/shared/classes/components/data-table/default-actions/edit-action";
import Model from "@/shared/classes/model";
import IModelResponse from "@/shared/interfaces/modules/model-response.interface";
import router from "@/shared/router";
import DeleteAction from "@/shared/classes/components/data-table/default-actions/delete-action";
import AreYouSureDialog from "@/shared/components/dialogs/AreYouSureDialog.vue";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import DataTable from "@/shared/components/data-table/DataTable.vue";
import CreateCoursesTestDialog from "@/admin/components/courses/CreateCoursesTestDialog.vue";
import { FilterOperators } from "@/shared/classes/components/data-table/data-table-filter";
import CoursesTest from "@/shared/modules/courses-tests/courses-test.model";
import CoursesTestsService from "@/shared/modules/courses-tests/courses-tests.service";
import CoursesTestTextHeader from "@/admin/components/courses/CoursesTestTextHeader.vue";
import HseRiskCategory from "@/shared/modules/hse-risk-categories/hse-risk-category.model";
import EditRiskCategoriesDialog from "@/admin/components/views/hse/risk-categories/EditRiskCategoriesDialog.vue";
import EditCoursesTestDialog from '@/admin/components/courses/EditCoursesTestDialog.vue';


@Component({
  components: { DataTable },
  methods: { __ }
})
export default class Test extends Vue {
  http = baseHttp;
  courseEntity: Course | null = null;
  table: DataTableBase | null = null

  created() {
    this.fetchCourse();

    this.table = new DataTableBase()
      .setModel(CoursesTest)
      .setEndpoint(`${getNodeApiUrlByVersion()}/course-tests`)
      .setDefaultFilters([
        {
          operator: FilterOperators.equals,
          key: 'courseUuid',
          value: this.$router.currentRoute.params.uuid
        }
        ])
      .setHeaders([
        new DataTableHeader()
          .setKey('text')
          .setSortable(false)
          .setValueComponent(CoursesTestTextHeader),
      ])
      .setActions([
        new EditAction().setAction(this.openEditDialog),
        new DeleteAction()
          .setAction((item: Course) => this.$store.dispatch(GlobalActions.showDialog, {
            show: true,
            component: AreYouSureDialog,
            meta: {
              title: __('admin.views.courses.tabs.test.table.actions.delete.title'),
              text: __('admin.views.courses.tabs.test.table.actions.delete.text', { name: item.name }),
              onYes: () => CoursesTestsService.destroy(item.uuid)
                .then(() => {
                  this.tableRef.refresh()
                  this.$store.dispatch(GlobalActions.showSnackBar, {
                    type: SnackBarTypes.success,
                    message: __('admin.views.courses.tabs.test.table.actions.delete.on-success'),
                  })
                })
            }
          }))
      ])
  }

  fetchCourse() {
    this.http.get(`${getNodeApiUrlByVersion()}/courses/${this.$router.currentRoute.params.uuid}?hasTranslations=true`)
      .then(response => this.courseEntity = response.data)
      .catch(console.warn)
  }

  openTestTitleDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CoursesTestTitleDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        item: this.courseEntity,
        onSuccess: () => this.fetchCourse()
      },
    })
  }

  openTestDescriptionDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CoursesTestDescriptionDialog,
      maxWidth: 570,
      persistent: true,
      meta: { item: this.courseEntity },
    })
  }

  openTestCompletionNoticeDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CoursesTestCompletionNoticeDialog,
      maxWidth: 570,
      persistent: true,
      meta: { item: this.courseEntity },
    })
  }

  openTestPassingThresholdDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CoursesTestPassingThresholdDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        item: this.courseEntity,
        onSuccess: () => this.fetchCourse()
      },
    })
  }

  openAddTestDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CreateCoursesTestDialog,
      maxWidth: 970,
      persistent: true,
      meta: {
        courseUuid: this.$router.currentRoute.params.uuid,
        onSuccess: () => this.tableRef.refresh()
      }
    })
  }

  openEditDialog(item: CoursesTest): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EditCoursesTestDialog,
      maxWidth: 970,
      persistent: true,
      meta: {
        item,
        onSuccess: () => this.tableRef.refresh(),
      },
    })
  }

  get courseTestTitle() {
    return this.courseEntity?.testTitle?.[localeHelper.getLocale() as any]
  }

  get courseTestPassingThreshold() {
    return this.courseEntity?.testPassingThreshold
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
