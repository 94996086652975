







import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'

@Component({
  methods: {
    __
  }
})
export default class CoursesTestTextHeader extends Vue {
  @Prop() item!: any
  text: string = ''

  mounted() {
    this.text = (this.$refs.text as any)?.innerText;
  }

  updated() {
    this.text = (this.$refs.text as any)?.innerText;
  }
}
